
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            mb-3
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">
                  <router-link
                    :to="{ name: 'layout.washsubscription' }"
                    class=""
                    >Wash Subscriptions</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Wash Subscription View
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5" v-if="this.wash">
        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <h5 class="font-weight-bold pb-2">Wash Subscription Info</h5>
              <table class="table table-borderless mb-0">
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Number</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.id }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Building</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.building ? this.wash.building.name : " - " }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Parking Slot</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        this.wash.parkingSlot ? this.wash.parkingSlot : " - "
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Package</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{
                        this.wash.package ? this.wash.package.type.name : " - "
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Status</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.status }}
                    </p>
                  </td>
                </tr>
                <tr v-if="this.wash.tax" class="white-space-no-wrap">
                  <td class="text-muted pl-0">Activation Link</td>
                  <td>
                    <div id="payment-id">
                      {{ this.wash.activationUrl }}
                    </div>
                    <button
                      class="btn btn-warning btn-sm mr-1"
                      @click="handlePaymentLink('payment-id')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M13.508 11.504l.93-2.494 2.998 6.268-6.31 2.779.894-2.478s-8.271-4.205-7.924-11.58c2.716 5.939 9.412 7.505 9.412 7.505zm7.492-9.504v-2h-21v21h2v-19h19zm-14.633 2c.441.757.958 1.422 1.521 2h14.112v16h-16v-8.548c-.713-.752-1.4-1.615-2-2.576v13.124h20v-20h-17.633z"
                        />
                      </svg>
                      Copy
                    </button>
                  </td>
                </tr>
                <tr v-if="this.wash.activatedAt">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Activated At</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.activatedAt | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr v-if="this.wash.lastReleaseAt">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Last Release At</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.lastReleaseAt | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr v-if="this.wash.nextReleaseAt">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Next Release At</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.nextReleaseAt | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr v-if="this.wash.validUpto">
                  <td class="p-0">
                    <p class="mb-0 text-muted">Valid Upto</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.validUpto | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Created At</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.createdAt | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Updated At</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ this.wash.updatedAt | formatDate }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Assigned To</p>
                  </td>

                  <td class="text-left" v-if="!!this.wash.assignedTo">
                    {{
                      this.wash.assignedTo.firstName +
                      " " +
                      this.wash.assignedTo.lastName
                    }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="text-secondary mx-1 job-info-svg"
                      width="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      @click="handleAssignWasherInfo()"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </td>
                  <td class="text-left" v-else>
                    <button
                      class="btn btn-success btn-sm"
                      @click="handleAssignWasherInfo()"
                    >
                      <img class="reset-btn-padding" :src="assignUserIcon" />
                      Assign
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="p-0">
                    <p class="mb-0 text-muted">Notes</p>
                  </td>
                 <td>
                    <p class="mb-0 pre">
                      {{ this.wash.notes ? this.wash.notes : " - " }}
                    </p>
                  </td>
                </tr>
              </table>
            </li>
            <li class="list-group-item p-3">
              <h5 class="font-weight-bold pb-2">Total Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr
                      v-if="this.wash.totalBeforeDiscount"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Total (Before Discount)</td>
                      <td class="text-monospace">
                        {{
                          this.wash.totalBeforeDiscount.amount.toLocaleString(
                            "en-US"
                          ) +
                          " " +
                          this.wash.totalBeforeDiscount.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.wash.discountName" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Discount Name</td>
                      <td class="text-monospace">
                        {{
                          this.wash.discountName 
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.wash.discount" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Discount</td>
                      <td class="text-monospace">
                        {{
                          this.wash.discount.amount.toLocaleString("en-US") +
                          " " +
                          this.wash.discount.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.wash.tax" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Tax</td>
                      <td class="text-monospace">
                        {{
                          this.wash.tax.amount.toLocaleString("en-US") +
                          " " +
                          this.wash.tax.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.wash.total" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Total (Incl. Tax)</td>
                      <td class="text-monospace">
                        {{
                          this.wash.total.amount.toLocaleString("en-US") +
                          " " +
                          this.wash.total.currency
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            <li class="list-group-item p-3" v-if="this.wash.paymentMethod">
              <h5 class="font-weight-bold pb-2">Payment Info</h5>

              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody v-if="this.wash.paymentSource">
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Payment Method</td>
                      <td>{{ this.wash.paymentMethod.name }}</td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Payment Status</td>
                      <td>{{ this.wash.paymentSource.invoice.status }}</td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Payment Details</td>
                      <td v-if="this.wash.paymentSource.invoice.paymentDetails">
                        {{ this.wash.paymentSource.invoice.paymentDetails }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item p-3">
                  <h5 class="font-weight-bold pb-2">Customer Info</h5>
                  <div class="table-responsive">
                    <table class="table table-borderless mb-0">
                      <tbody v-if="this.wash.customer">
                        <tr class="white-space-no-wrap">
                          <td class="text-muted pl-0">Name</td>
                          <td>
                            {{
                              this.wash.customer.firstName +
                              " " +
                              this.wash.customer.lastName
                            }}
                          </td>
                        </tr>
                        <tr class="white-space-no-wrap">
                          <td class="text-muted pl-0">Phone</td>
                          <td>
                            <!-- <router-link
                              class="navigation-link-text"
                              :to="
                                '/customerview/' + this.wash.customer.phone
                              "
                            > -->
                              {{ this.wash.customer.phone }}
                              <!-- <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="text-secondary mx-1 job-info-svg"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg> -->
                            <!-- </router-link> -->
                          </td>
                        </tr>

                        <tr class="white-space-no-wrap">
                          <td class="text-muted pl-0">Email</td>
                          <td>
                            {{ this.wash.customer.email }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        -
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item p-3">
                  <h5 class="font-weight-bold pb-2">Car Info</h5>
                  <div class="table-responsive">
                    <table class="table table-borderless mb-0">
                      <tbody v-if="this.wash.car">
                        <tr class="white-space-no-wrap">
                          <td class="text-muted pl-0">Car</td>
                          <td v-if="this.wash.customer">
                            {{
                              this.wash.car.model.make.name +
                              " " +
                              this.wash.car.model.name
                            }}
                          </td>
                        </tr>
                        <tr class="white-space-no-wrap">
                          <td class="text-muted pl-0">Year</td>
                          <td>{{ this.wash.car.year }}</td>
                        </tr>
                        <tr class="white-space-no-wrap">
                          <td class="text-muted pl-0">Plate No</td>
                          <td>{{ this.wash.car.licensePlate }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item p-3">
                  <div class="d-flex justify-content-between align-items-center">
                  <h5 class="font-weight-bold pb-2">Scheduled Slots Info</h5>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-secondary mx-1 job-info-svg"
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="handleSlotInfo()"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr class="text-muted">
                          <th scope="col">Week Day</th>
                          <th scope="col" class="text-right">Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in wash.scheduledSlots"
                          :key="index"
                        >
                          <td>
                            {{ capitalizeFirstLetter(item.weekDay) }}
                          </td>
                          <td class="text-right">
                            {{ item.fromTime | formatTime }} -
                            {{ item.toTime | formatTime }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <ul class="list-group list-group-flush">
                <li class="list-group-item p-3">
                  <div class="d-flex justify-content-between align-items-center">
                  <h5 class="font-weight-bold pb-2">Comments</h5>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-secondary mx-1 job-info-svg"
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="handleComment()"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                  </div>
                  <div class="table-responsive divScroll"  v-if="wash.comments && wash.comments.length > 0">
                    <div v-for="(item, index) in wash.comments"
                          :key="index">
                      <div >
                        <span style="font-size: 16px; font-weight: bold; color: black;">{{ item.createdBy.firstName }}</span>
                         - 
                        <span style="font-size: 12px;">{{ item.createdAt | formatDate}}</span>
                      </div>
                      <div style="font-size: 16px; margin-bottom: 10px;">
                        {{ item.comment }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-3" style="text-align: center;">
                    No Comments
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Assign Modal -->
      <b-modal
        v-model="assignModalShow"
        id="modal-center"
        centered
        title="Assign"
        size="md"
      >
        <div class="mt-2 mb-2">
          <label class="form-label text-muted filter-label-heading mt-3"
            >Assigned To</label
          >
          <multiselect
            v-model="assignedTo"
            :options="assignedToArray"
            :multiple="false"
            :custom-label="customLabelAssignedTo"
            :show-labels="false"
            :allow-empty="false"
            :searchable="true"
            :internal-search="false"
            label="name"
            key="name"
            @search-change="handleSearchCustomer"
          >
          </multiselect>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="assignWasher"
            >
              Select
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Scheduled Slot Modal -->
      <b-modal
        v-model="slotModalShow"
        id="modal-center"
        centered
        title="Schedule Slot"
        size="md"
      >
        <div class="d-flex justify-content-between">
          <label for="car-model" class="form-label font-weight-bold text-muted">
            Slots *
          </label>
        </div>
        <div v-for="(el, index) in slotsWeekOptions" :key="index">
          <div class="row">
            <div class="col mb-6">
              <multiselect
                v-model="slotDay[index]"
                :value="
                  wash.scheduledSlots[index]
                    ? wash.scheduledSlots[index].weekDay
                    : ''
                "
                placeholder="Slot Day"
                label="weekDay"
                :options="
                  weekDayOptions.filter((v) => 
                    v.weekDay == el.weekDay && v.weekDay == wash.scheduledSlots[index].weekDay
                      ? true
                      : !slotDay.find((e) => e.weekDay === v.weekDay)
                  )
                "
                :multiple="false"
                :taggable="false"
                @select="(event) => onSelectedSlotDay(event, index)"
              ></multiselect>
            </div>
            <div class="col mb-6">
              <multiselect
                v-if="slotDay[index]"
                label="label"
                v-model="selectedStates[index].values"
                placeholder="Slot Time"
                :options="getOptions(slotDay[index].id)"
                :multiple="false"
                :taggable="false"
                @select="(event) => onSelectedSlotTime(event, index)"
              >
              </multiselect>
            </div>
          </div>
          <br />
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="slotUpdate"
            >
              Update
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Update Comment -->
      <b-modal
        v-model="commentModalShow"
        id="modal-center"
        centered
        title="Add Comment"
        size="md"
      >
        <div class="d-flex justify-content-between">
          <div class="col-md-12 mb-3">
          <label for="car-model" class="form-label font-weight-bold text-muted">
            Comment *
          </label>
          <div>
            <textarea
              rows="2"
              class="custom-input notesArea"
              type="text"
              v-model="comment"
            ></textarea>
          </div>
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="commentUpdate"
            >
              Update
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>



<script>
import {
  BWS_DASHBOARD_WASH_SUBSCRIPTION,
  GET_BWS_DASHBOARD_WASHERS,
  BWS_DASHBOARD_SUBSCRIPTION_SLOTS,
} from "../../../graphql/query";
import { WASH_SUBSCRIPTION_UPDATE } from "../../../graphql/mutation";
export default {
  name: "WashSubscriptionView",
  props: ["washId"],
  data() {
    return {
      comment: "",
      wash: [],
      slots: [],
      slotDay: [],
      slotTime: [],
      slotTimeArray: [],
      slotsWeekOptions: [],
      weekDayOptions: [],
      weekTimeOptions: [],
      scheduledSlotsArray: [],
      selectedStates: [],
      slotModalShow: false,
      assignModalShow: false,
      commentModalShow: false,
      assignedTo: null,
      assignedToArray: [],
      assignUserIcon: require("../../../assets/images/svg/user-check.svg"),
    };
  },
  mounted() {
    this.getWashSubscriptionDetails();
  },

  computed: {
    computedDevices() {
      // filter by conditions here
      return this.slotsWeekOptions.map((el) => {
        this.weekDayOptions.filter((v) => v.weekDay == el.weekDay);
      });
    },
  },
  methods: {
    slotUpdate() {
      this.slotModalShow = false;
      this.$apollo
        .mutate({
          mutation: WASH_SUBSCRIPTION_UPDATE,
          variables: {
            washSubscriptionId: this.wash.id,
            scheduledSlots: this.slotTimeArray,
          },
        })
        .then(() => this.getWashSubscriptionDetails());
    },
    handleComment() {
      this.commentModalShow = true;
    },
    commentUpdate() {
      if(this.comment == null || this.comment == ""){
        this.$bvToast.toast("Comment is required", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true,
          toastClass: "text-center toast-box toast-style",
        });
      } else {
      this.commentModalShow = false;
      this.$apollo
        .mutate({
          mutation: WASH_SUBSCRIPTION_UPDATE,
          variables: {
            washSubscriptionId: this.wash.id,
            comment: this.comment,
          },
        })
        .then(() => this.getWashSubscriptionDetails());
      }
    },
    onSelectedSlotDay(selection, index) {
      this.selectedStates.push({
        id: selection.id,
        values: [],
      });
      this.selectedStates[index].values = null;
    },

    onSelectedSlotTime(selection, index) {
      this.selectedStates[index].id = this.slotDay[index].id;
      this.slotTimeArray[index] = selection.id;
    },
    getOptions(id) {
      const option = this.weekDayOptions.find((item) => item.id == id);
      if (option) return option.options;
      return [];
    },
    getScheduledSlots() {
      let arr = [];
      let arrayValue = [];
      if (this.wash.package.id) {
        this.slots.forEach((val) => {
          let index = arr.findIndex((v) => v.weekDay === val.weekDay);
          if (index > -1) {
            arr[index].options.push({
              label:
                this.formatTime(val.fromTime) +
                " - " +
                this.formatTime(val.toTime),
              fromTime: val.fromTime,
              toTime: val.toTime,
              id: val.id,
            });
          } else {
            arr.push({
              ...val,
              options: [
                {
                  label:
                    this.formatTime(val.fromTime) +
                    " - " +
                    this.formatTime(val.toTime),
                  fromTime: val.fromTime,
                  toTime: val.toTime,
                  id: val.id,
                },
              ],
            });
          }
        });
        arrayValue = arr.filter(
          (e, i) => i + 1 <= this.wash.package.type.weeklyWashes
        );
      }
      this.slotsWeekOptions = arrayValue;
      this.weekDayOptions = arr.map((m) => ({
        ...m,
        weekDay: m.weekDay,
      }));
      this.slotsWeekOptions.map((el) => {
        this.weekDayOptions.filter((v) => v.weekDay == el.weekDay);
      });
      for (var i = 0; i < this.wash.scheduledSlots.length; i++) {
        this.slotDay[i] = this.wash.scheduledSlots[i];
        this.onSelectedSlotDay(this.wash.scheduledSlots[i], i);
        this.selectedStates[i].values = [
          {
            label:
              this.formatTime(this.wash.scheduledSlots[i].fromTime) +
              " - " +
              this.formatTime(this.wash.scheduledSlots[i].toTime),
            fromTime: this.wash.scheduledSlots[i].fromTime,
            toTime: this.wash.scheduledSlots[i].toTime,
            id: this.wash.scheduledSlots[i].id,
          },
        ];
        this.slotTimeArray[i] = this.wash.scheduledSlots[i].id
      }
    },
    formatTime(timeString) {
      const timeString12hr = new Date(
        "1970-01-01T" + timeString + "Z"
      ).toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });

      return timeString12hr;
    },
    handleSlotInfo() {
      this.slotModalShow = true;
      this.getSlots();
    },
    handleAssignWasherInfo() {
      this.assignModalShow = true;
      this.assignedTo = null;
      this.$apollo
        .mutate({
          mutation: GET_BWS_DASHBOARD_WASHERS,
          variables: {
            first: 10,
            search: this.search,
          },
        })
        .then((data) => {
          this.washers = data.data.bwsDashboardWashers.edges;
          this.assignedToArray = this.washers.map((res) => {
            return res.node;
          });
        });
    },

    handleSearchCustomer(event) {
      if (event) {
        this.search = event;
        this.handleAssignWasherInfo();
      }
    },

    customLabelAssignedTo(opt) {
      if (opt.firstName) {
        return opt.firstName + " " + opt.lastName;
      }
    },
    assignWasher() {
      this.assignModalShow = false;
      this.$apollo
        .mutate({
          mutation: WASH_SUBSCRIPTION_UPDATE,
          variables: {
            washSubscriptionId: this.wash.id,
            assignedToId: this.assignedTo.id,
          },
        })
        .then(() => this.getWashSubscriptionDetails());
    },
    capitalizeFirstLetter(str) {
      str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });

      return str;
    },
    async getWashSubscriptionDetails() {
      this.$apollo
        .query({
          query: BWS_DASHBOARD_WASH_SUBSCRIPTION,
          variables: {
            id: this.washId,
          },
        })
        .then((data) => {
          this.wash = data.data.bwsDashboardSubscription;
        });
    },
    handlePaymentLink(id) {
      // let url = this.order.paymentSource.invoice.paymentLink;
      // window.open(url, "_blank");
      var copyText = document.getElementById(id).innerText;
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      var url = copyText;
      input_temp.innerHTML = url;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      // input_temp.value + " " +
      this.$bvToast.toast(`${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },

    async getSlots() {
      await this.$apollo
        .query({
          query: BWS_DASHBOARD_SUBSCRIPTION_SLOTS,
          variables: {
            id: this.wash.id,
          },
        })
        .then((data) => {
          this.slots = data.data.bwsDashboardSubscriptionSlots;
        });
      this.getScheduledSlots();
    },
  },
};
</script>
<style>
#carTable thead tr,
#orderTable thead tr {
  background-color: rgba(51, 120, 255, 0.05);
}
/* table.table-customer {
    text-align: center;
} */
.table.table-customer th,
.table.table-customer td {
  padding: 12px 12px;
}
.custom-search-input {
  height: 30px !important;
}

.table th.th-padding-class {
  padding: 0px;
}
.white-space {
  overflow: auto;
  white-space: nowrap;
}

/* .table-zoom {
  zoom: 90%;
} */

.toast-style {
  font-size: 1rem;
  padding: 10px;
}

.car-license-plate:hover,
.order-num:hover {
  color: blue;
  cursor: pointer;
}

.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.serviceName-div {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.voucher-name {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.voucher-code {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.text-monospace {
  font-family: monospace, monospace !important;
  text-align: right;
}
#payment-id {
  display: none;
}

.pre {
  padding: 2px 0px;
  font-size: 15px;
}

.singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.multiselect {
  min-height: 10px !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}
.multiselect__select {
  height: 31px !important;
}
.notesArea{
    line-height: 25px !important;
}
.divScroll {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 80px;
  overflow-y: scroll;
  padding: 5px;
}
.custom-input {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .table.table-customer {
    display: table;
  }
  .table.table-customer th,
  .table.table-customer td {
    padding: 10px 5px;
  }
}
</style>

